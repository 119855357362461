import { http as node} from '../services/node-service'

export const getData = {
    methods: {
        async getImages(){
            try {
                const images = await node.get("containers/getImages");
                if(process.env.VUE_APP_VIDVIE == 2){
                    let main = images.data.data.images.filter(image => image.type === 'bannerMain_vidvie')
                    let secondary = images.data.data.images.filter(image => image.type === 'bannerSecondary_vidvie')

                    let banners = { main, secondary }
                    this.$store.commit("home/SET_BANNERS", banners)
                } else { 
                    let main = images.data.data.images.filter(image => image.type === 'bannerMain')
                    let secondary = images.data.data.images.filter(image => image.type === 'bannerSecondary')

                    let banners = { main, secondary }
                    this.$store.commit("home/SET_BANNERS", banners)
                }
            } catch (e) {
            console.log(e);
            }
        },
        async getData(){
            try {
                await this.$store.dispatch("common/getConfigurations")
                
                if(!localStorage.getItem("lang")){
                    const locale = this.configurations.general.web_page.statusLanguage.text?.toLowerCase() ?? 'es';
                    this.$i18n.locale = locale;
                }
                await this.getImages()
                await this.$store.dispatch('branches/getBranches');
                await this.checkBranchSelected()
                if (this.isLogged){
                    await this.$store.dispatch("cart/getMyCart")
                    await this.$store.dispatch("account/getAddresses")
                    await this.$store.dispatch('wishlist/getLists');
                }else{
                    if(this.branchSelected)
                    await this.$store.dispatch('cart/getCartByBranch', {codplace: this.branchSelected.codplace}); 
                }
            } catch (error) {
                console.log(error)
                // this.connectionError = true
            } finally {
                this.$store.commit("common/SET_STATE_LOADING", false)
            }
        },
        async checkBranchSelected(){
            if(!this.branchSelected){
                try {
                    navigator.geolocation.getCurrentPosition(
                    async (position) => {
                            await this.$store.dispatch("branches/nearestBranch", {latitude : position.coords.latitude, longitude : position.coords.longitude})
                    }, (positionError)=>{
                        console.log(positionError);
                        this.$store.commit("branches/SET_BRANCH", this.configurations?.general?.web_page?.default_point_of_sale || 1)
                    })
                } catch (error) {
                    console.log(error);
                }
            }
        },
        // initChat(){
        //   window.fcWidget.init({
        //     token: "00957504-d6a6-48b0-8a1e-f348275a6cd0",
        //     host: "https://wchat.freshchat.com",
        //   })
        // }
    },
}
