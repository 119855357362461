<template>
  <div class="container home-container">
    <button v-if="false" @click="darkModeFunction" class="btn btn-secondary mt-2">change</button>
    <slider class="principal-slider" @sliderLoaded="sliderLoaded = true" />
    <perks-container :sliderLoaded="sliderLoaded" />
    <div v-if="!loading">
      <product-slider class="animation-slide-down" />
      <advertisements />
      <suggested-categories class="animation-slide-down" />
      <parallax-container class="animation-slide-down" />
      <offers-flash v-if="false" />
      <brands-slider v-if="vidvie == 0" class="animation-slide-down" />
      <wide-cards-container v-if="viewPort('sm')" />
      <community-collage v-if="viewPort('sm')" />
      <category-slider v-if="viewPort('sm')" />
      <season-cards />
      <main-categories-card />
      <recommended-products />
    </div>
  </div>
</template>
<script>
import Slider from '../components/sliders/Slider.vue'
import ProductSlider from '../components/ProductSlider.vue'
import BrandsSlider from '../components/sliders/BrandsSlider.vue'
import Advertisements from '../components/advertisements/Advertisements.vue'
import WideCardsContainer from '../components/wide-cards/WideCardsContainer.vue'
import CategorySlider from '../components/sliders/category_slider/CategorySlider.vue'
import SeasonCards from '../components/season-cards/SeasonCards.vue'
import MainCategoriesCard from '../components/main-categories/MainCategoriesCard.vue'
import CommunityCollage from '../components/community-collage/CommunityCollage.vue'
import SuggestedCategories from '../components/SuggestedCategories.vue'
import RecommendedProducts from '../components/RecommendedProducts.vue'
import OffersFlash from '../components/sliders/OffersFlash.vue'

export default {
  name: 'Home',
  components: {
    PerksContainer: () => import("../components/perks/PerksContainer"),
    ParallaxContainer: () => import("@/modules/home/components/parrallax/ParallaxContainer"),
    Slider,
    ProductSlider,
    BrandsSlider,
    Advertisements,
    SuggestedCategories,
    WideCardsContainer,
    CategorySlider,
    SeasonCards,
    MainCategoriesCard,
    CommunityCollage,
    RecommendedProducts,
    OffersFlash
  },
  data() {
    return {
      sliderLoaded: false,
      theme: false,
      loading: true,
      vidvie: process.env.VUE_APP_VIDVIE
    }
  },
  methods: {
    darkModeFunction() {
      this.theme = !this.theme
      this.$store.commit('home/CHANGE_TO_VIDVIE', this.theme)
      let theme = this.theme ? 'VIDVIE' : ''; //toggles theme value
      document.documentElement.setAttribute('data-theme', theme); // sets the data-theme attribute
    }
  },
  computed: {
    getDimensions() {
      return window.innerWidth <= 1400
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 200);
  },
  created() {
    this.$store.commit('products/RESET_FILTERS')
  }
}
</script>
